<template>
  <div class="bigbox">
    <nav2 />
    <div class="inputbox">
      <div style="padding-top:15%;">
        <div >
          <!-- <p style="margin:10px 9%">原手机号：</p> -->
          <van-field v-model="phone"  disabled class="phon" type="text" placeholder="请输入旧手机号" />
        </div>
        <div>
           <van-form validate-first>
            <van-field  :rules="[{ pattern }]" v-model="newphone" class="phon"  :type="passwordType" placeholder="请输入新手机号" />
           </van-form>
        </div>
        <div>
          <!-- <p style="margin:10px 9%">新手机号：</p> -->
          <input style="text-indent：10px" v-model="code1" class="newphon" type="text"  placeholder="请输入验证码" />      
          <button class="btn" :disabled='this.is_send'  @click="send_sms">{{ sms_interval }}</button>       
        </div>
      </div>
    </div>
    <button class="btnbox"  @click="okbtn">确认修改</button>
  </div>
</template>

<script>
import { code_api, newphone_api } from '../http/api/certification'
import nav2 from '@/components/Nav2.vue'
import { Toast } from 'vant';
export default {
  components: {
    nav2,
  },
  name: 'TaxiH5webPhon',

  data() {
    return {
       passwordType: '', //输入框类型
      newphone: '',
      sms_interval: '验证码',
      phone:null,
      code1:'',
      is_send:false,
       pattern: /^1[3456789]\d{9}$/,
    }
  },

  mounted() {
     this.phone = JSON.parse(sessionStorage.getItem('token')).phone
  },

  methods: {
    async code() {
      let res = await code_api({
        phone: this.phone,
      })
      console.log(res)
      if (res.code == 200) {
        this.$notify({
                        message: res.message,
                        background: '#00BFFF',
                        duration: 1000
                    })
      }
    },
    //验证码
    send_sms() {
      if(this.passwordType!=''){
 if (this.is_send) return
      this.is_send = true
      let sms_interval_time = 60
      this.sms_interval = '发送中...'
      let timer = setInterval(() => {
        if (sms_interval_time <= 1) {
          clearInterval(timer)
          this.sms_interval = '验证码'
          this.is_send = false // 重新回复点击发送功能的条件

        } else {
          sms_interval_time -= 1
          this.sms_interval = `${sms_interval_time}秒后再发`
        }
      }, 1000)
      this.code()
      }else{
        Toast('手机号不能为空');
      }
       
      
    },
    //确认修改
    async okbtn() {
       if (this.code != '' && this.newphone != '' ) {
         try{
        let res = await newphone_api({
        phone: this.phone, //当前账号手机号
        newPhone: this.newphone, //更换手机号
        code: this.code1, //验证码
      })
     
      if(res.code==200){
        
        var user = JSON.parse(sessionStorage.getItem('token'))
        console.log(user)
        user.phone=this.newphone
        console.log(this.newphone);
        sessionStorage.setItem("token",JSON.stringify(user))
      this.$router.back()
      }
      }catch (err){
        console.log(err)
      }
       }
      
      
    },
  },
}
</script>

<style lang="scss" scoped>
.bigbox {
  width: 100%;
  height: 100vh;
  background: #eee;
  font-size: 14px;
color: #000;
  input {
    border: 0;
    background: #fff;
  }
  .inputbox {
    margin-top: 20px;
    background: #fff;
    height: 300px;
    .phon {
      width: 80%;
      height: 35px;
      margin: 10px 9%;
      border-bottom: solid 1px #eee;
    }
    .newphon {
      text-indent: 13px;
      width: 60%;
      height: 35px;
      font-size: 12px;
      margin: 10px 0 0 9%;
      border-bottom: solid 1px #eee;
      background-color:transparent;
    }
    input::-webkit-input-placeholder {
       /* placeholder颜色  */
	    color: #ccc;
	/* placeholder字体大小  */
	    font-size: 12px;
	/* placeholder位置  */
	   
	 }
    .btn {
      width: 20%;
      height: 40px;
       border: 0;
       background: #fff;
      color: #1e90ff;
      border-bottom: solid 1px #eee;
      font-size: 12px;
     
    }
  }
  .btnbox {
    width: 80%;
    height: 40px;
    background-color: #eb4541;
    border: 0;
    border-radius: 15px;
    color: #fff;
    margin-top: 20%;
    margin-left: 10%;
  }
}
</style>